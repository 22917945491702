const HEADER_COMPONENT = 'js-header-components';
const STICKY_HEADER_COMPONENT = 'component-WebsiteHeaderComponent--sticky';
const STICKY_SCROLLUP_CLASS = 'component-WebsiteHeaderComponent--sticky-scrollup';
export class StickyHeader {
  constructor() {
    this.headerComponent = document.querySelector(`.${HEADER_COMPONENT}`);
    this.observer = null;
    this.lastScrollY = window.scrollY;
    this.lastIntersectionTop = 0;
    this.handleIntersectionCallback = entries => {
      this.handleIntersection(entries, this.unfixHeader.bind(this), this.fixHeader.bind(this));
    };
  }
  init() {
    this.initObserver();
    this.trackScrollDirection();
  }
  initObserver() {
    if (this.headerComponent) {
      this.observer = new IntersectionObserver(this.handleIntersectionCallback, {
        root: null,
        rootMargin: '0px',
        threshold: 1
      });
      this.observer.observe(this.headerComponent);
    }
  }
  handleIntersection(entries, isIntersectingMethod, isNotIntersectingMethod) {
    entries.forEach(entry => {
      if (entry.boundingClientRect.top !== this.lastIntersectionTop) {
        document.body.style.setProperty('--headerHeight', `${entry.target.offsetHeight}px`);
        if (entry.isIntersecting) {
          isIntersectingMethod();
        } else {
          isNotIntersectingMethod();
        }
      }
      this.lastIntersectionTop = entry.boundingClientRect.top;
    });
  }
  fixHeader() {
    if (this.headerComponent) {
      this.headerComponent.classList.add(`${STICKY_HEADER_COMPONENT}`);
    } else {
      throw new Error('hide header error: Header querySelector is undefined');
    }
  }
  unfixHeader() {
    if (this.headerComponent) {
      this.headerComponent.classList.remove(`${STICKY_HEADER_COMPONENT}`);
      this.headerComponent.classList.remove(STICKY_SCROLLUP_CLASS);
    } else {
      throw new Error('hide header error: Header querySelector is undefined');
    }
  }
  trackScrollDirection() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }
  handleScroll() {
    const currentScrollY = window.scrollY;
    if (currentScrollY < this.lastScrollY) {
      this.addScrollUpClass();
    } else {
      this.removeScrollUpClass();
    }
    this.lastScrollY = currentScrollY;
  }
  addScrollUpClass() {
    if (this.headerComponent) {
      this.headerComponent.classList.add(STICKY_SCROLLUP_CLASS);
    }
  }
  removeScrollUpClass() {
    if (this.headerComponent) {
      this.headerComponent.classList.remove(STICKY_SCROLLUP_CLASS);
    }
  }
}