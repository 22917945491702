import { StickyHeader } from './sticky/stickyHeader.src';
class WebsiteHeaderComponentMobile extends lm.Composant {
  constructor(id) {
    super(id);
  }
  init() {
    const stickyInstance = new StickyHeader();
    stickyInstance.init();
  }
}
window.onload = () => {
  const WebsiteHeaderComponentInstance = new WebsiteHeaderComponentMobile('WebsiteHeaderComponent');
  WebsiteHeaderComponentInstance.init();
};